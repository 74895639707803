import React from "react";
import { Route, Switch } from "react-router";
import ReportWithSearchAndFilterTable from "../common/ReportWithSearchAndFilterTable";
import { ConnectionRegistration } from "../connectionRegistration/ConnectionRegistration";
import { AddressRegistration } from "../customerPortal/AddressRegistration";
import CustomerInvoiceListDataInfo from "../customerPortal/CustomerInvoiceListDataInfo";
import CustomerPortal, {
  SidebarMenuItem,
} from "../customerPortal/CustomerPortalPage";
import CustomerPortalInvoiceDetail from "../customerPortal/CustomerPortalInvoiceDetail";
import RefillPanel from "../customerPortal/RefillPanel";
import { Routes } from "./Routes";
import CustomerConnectionPanel from "../customerPortal/CustomerConnectionPanel";
import CustomerAddressListPanel from "../customerPortal/CustomerAddressListPanel";
import ClientCdrReportDataInfo from "../customerPortal/ClientCdrReportDataInfo";
import ConnectionDetailPanel from "../common/ConnectionDetailPanel";
import CustomerExistingConnectionReqPanel from "../customerPortal/CustomerExistingConnectionReqPanel";
import { CustomerSupportReqPanel } from "../customerPortal/CustomerSupportReqPanel";
import CustomerSupportListDataInfo from "../customerPortal/CustPortalSupportListDataInfo";
import CustomerPortalSupportDetail from "../customerPortal/CustomerPortalSupportDetail";
import CustomerCdrReportPanel from "../customerPortal/CustomerCdrReportPanel";
import CustomerCallSummaryReportPanel from "../customerPortal/CustomerCallSummaryReportPanel";


export const CustomerPortalRoutes = (props) => {
  return (
    <div>
      <Switch>
        <Route path={Routes.CUSTOMER.PORTAL.HOME} exact>
          <CustomerPortal
            activePanel={SidebarMenuItem.CONNECTION_LIST}
            currentPanel={<CustomerConnectionPanel />}
          />
        </Route>
        <Route path={Routes.CUSTOMER.PORTAL.CONNECTION_LIST} exact>
          <CustomerPortal
            activePanel={SidebarMenuItem.CONNECTION_LIST}
            currentPanel={<CustomerConnectionPanel />}
          />
        </Route>
        <Route path={Routes.CUSTOMER.PORTAL.EXISTING_CONNECTION_REQUEST} exact>
          <CustomerPortal
            activePanel={SidebarMenuItem.EXISTING_CONNECTION_REQUEST}
            currentPanel={<CustomerExistingConnectionReqPanel />}
          />
        </Route>
        <Route
          path={Routes.CUSTOMER.PORTAL.CONNECTION_DETAIL + "/:connectionId"}
          exact
        >
          <CustomerPortal
            activePanel={SidebarMenuItem.CONNECTION_DETAIL}
            currentPanel={<ConnectionDetailPanel />}
          />
        </Route>
        <Route path={Routes.CUSTOMER.PORTAL.ADDRESS_LIST} exact>
          <CustomerPortal
            activePanel={SidebarMenuItem.ADDRESS_LIST}
            currentPanel={<CustomerAddressListPanel />}
          />
        </Route>

        <Route
          path={Routes.CUSTOMER.PORTAL.REQUEST_CONNECTION + "/:custId"}
          exact
        >
          <CustomerPortal
            activePanel={SidebarMenuItem.REQUEST_CONNECTION}
            currentPanel={<ConnectionRegistration />}
          />
        </Route>
        <Route
          path={Routes.CUSTOMER.PORTAL.REGISTER_ADDRESS + "/:customerId"}
          exact
        >
          <CustomerPortal
            activePanel={SidebarMenuItem.REGISTER_ADDRESS}
            currentPanel={<AddressRegistration />}
          />
        </Route>
        <Route
          path={Routes.CUSTOMER.PORTAL.INVOICE_LIST + "/:customerId"}
          exact
        >
          <CustomerPortal
            activePanel={SidebarMenuItem.INVOICE_LIST}
            currentPanel={
              <ReportWithSearchAndFilterTable
                dataInfo={CustomerInvoiceListDataInfo}
              />
            }
          />
        </Route>
        <Route
          path={Routes.CUSTOMER.PORTAL.INVOICE_DETAIL + "/:invoiceId"}
          exact
        >
          <CustomerPortal
            activePanel={SidebarMenuItem.INVOICE_DETAIL}
            currentPanel={<CustomerPortalInvoiceDetail />}
          />
        </Route>

        <Route path={Routes.CUSTOMER.PORTAL.REFILL} exact>
          <CustomerPortal
            activePanel={SidebarMenuItem.REFILL}
            currentPanel={<RefillPanel />}
          />
        </Route>
        <Route path={Routes.CUSTOMER.PORTAL.CDR} exact>
          <CustomerPortal
            activePanel={SidebarMenuItem.CDR}
            currentPanel={<CustomerCdrReportPanel/>}
          />
        </Route>
        <Route path={Routes.CUSTOMER.PORTAL.CALL_SUMMARY} exact>
          <CustomerPortal
            activePanel={SidebarMenuItem.CALL_SUMMARY}
            currentPanel={<CustomerCallSummaryReportPanel/>}
          />
        </Route>
        <Route path={Routes.CUSTOMER.PORTAL.SUPPORT_REQUEST} exact>
          <CustomerPortal
            activePanel={SidebarMenuItem.SUPPORT_REQUEST}
            currentPanel={<CustomerSupportReqPanel />}
          />
        </Route>
        <Route path={Routes.CUSTOMER.PORTAL.SUPPORT_LIST} exact>
          <CustomerPortal
            activePanel={SidebarMenuItem.SUPPORT_LIST}
            currentPanel={
              <ReportWithSearchAndFilterTable
                dataInfo={CustomerSupportListDataInfo}
              />
            }
          />
        </Route>
        <Route
          path={Routes.CUSTOMER.PORTAL.SUPPORT_DETAIL + "/:supportId"}
          exact
        >
          <CustomerPortal
            activePanel={SidebarMenuItem.SUPPORT_DETAIL}
            currentPanel={<CustomerPortalSupportDetail/>}
          />
        </Route>
      </Switch>
    </div>
  );
};
